body,
html {
  background-image: url("../src/assets/antara-exports/MainMenu/BackGround.png");
  /* background-color: #231510 !important; */
  background-repeat: repeat-y;
  /* background-size: contain; */
  /* overflow-y: auto; */
  overflow-y: auto;
  scrollbar-gutter: stable;
  /* overflow-x: hidden; */
  /* margin-right: calc(-1 * (100vw - 100%)); */
}
.pageMaxSize {
  /* min-height: 100vh; */
}
a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  cursor: pointer;
}
.App {
  text-align: center;
  /* background-image: url("../src/assets/blackmarket/BG-lines.png"); */
  background-repeat: repeat-y;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: "#242735" !important;
}
.Menu.Mui-active {
  background-color: "#242735" !important;
}
.minting-bg {
  background-image: url("../src/assets/antara-exports/LogoShadowOnMintingPage.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-position {
  background-repeat: no-repeat;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  color: #e1d7d3 !important;
}

/* ------ BlackMarket ------- */
@-webkit-keyframes autofill {
  to {
    color: #06070b;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: white !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px #06070b inset !important;
}

.my-toast {
  background-color: #fff;
  color: #333;
  border-radius: 4px;
}

.nft-card {
  /* clip-path: ellipse(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  ); */
  height: 324px;
  width: 234px;
  background-color: #1e0407 !important;
  border-radius: 0px;
}
.nft-container {
  /* clip-path: ellipse(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  ); */
  background-color: #e5d161 !important;
  background-image: radial-gradient(#e5d161, #cf9b36);
  height: 328px;
  width: 238px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

.header {
  background-image: url("../src/assets/antara-exports/MainMenu/HeaderBar.png");
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  align-items: "center";
  /* height: 90px; */
}
.navButton {
  background-image: url("../src/assets/antara-exports/MainMenu/Underline.png");
  background-size: auto 15px;
  background-position: 30% 70%;
  background-repeat: no-repeat;
  font-family: "Playfair Display SC, serif";
}

.btn-inner {
  /* clip-path: polygon(
    5% 0,
    95% 0,
    100% 20%,
    100% 80%,
    95% 100%,
    5% 100%,
    0% 80%,
    0% 20%
  ); */
  height: 40px;
  width: 150px;
  /* background: radial-gradient(
    circle,
    rgba(154, 44, 37, 1) 0%,
    rgba(101, 38, 33, 1) 100%
  ); */
}
.btn-border {
  /* clip-path: polygon(
    5% 0,
    95% 0,
    100% 20%,
    100% 80%,
    95% 100%,
    5% 100%,
    0% 80%,
    0% 20%
  ); */
  background-image: url("../src/assets/antara-exports/MainMenu/Btn.png");
  background-size: auto 40px;
  background-repeat: no-repeat;
}

.btn {
  height: 40px;
  width: 180px;
}

.btn-activity {
  background-image: url("../src/assets/antara-exports/MainMenu/Btn.png");
  background-size: auto 40px;
  background-repeat: no-repeat;
}

.detail-card {
  /* clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  ); */
  height: 620px;
  width: 470px;
  padding: 20px;
  background: "transparent";
  border-color: white;
}
.detail-container {
  /* clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  ); */
  background-color: "transparent";
  /* background-image: radial-gradient(#e5d161, #3f1d10); */
  height: 624px;
  width: 474px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: white;
}

.detail-corners {
  --mask: radial-gradient(30px at 30px 30px, #0000 98%, #000) -30px -30px;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.grid {
  background-position: center;
  background-color: rgba(84, 27, 32, 0.5);
  box-shadow: 0px 0px 12px 0px rgba(202, 70, 89, 0.3);
  border-radius: 6px;
  height: 60px;
  overflow-y: auto;
}

.centered-image {
  display: block;
  margin: 0 auto;
}

/* .card-corners {
  --mask: radial-gradient(20px at 20px 20px, #0000 98%, #000) -20px -20px;
  -webkit-mask: var(--mask);
  mask: var(--mask);
} */
