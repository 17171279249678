body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* @font-face {
  font-family: "PlayfairDisplaySC";
  src: local("PlayfairDisplaySC"),
    url("./assets/fonts/PlayfairDisplaySC-Regular.ttf") format("opentype");
} */
@font-face {
  font-family: "Kingthings Petrock";
  src: local("Kingthings Petrock"),
    url("./assets/fonts/Kingthings_Petrock.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "AVERIASERIFLIBRE";
  src: local("AVERIASERIFLIBRE"),
    url("./assets/fonts/AveriaSerifLibre-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "blackgold";
  src: local("blackgold"),
    url("./assets/fonts/blackgold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "blackgold-bold";
  src: local("blackgold-bold"),
    url("./assets/fonts/blackgold-bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "blackgold-extrabold";
  src: local("blackgold-extrabold"),
    url("./assets/fonts/blackgold-extrabold.ttf") format("truetype");
  font-weight: bold;
}
